@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body {
	font-family: "Roboto", sans-serif;
	margin: 0;
	padding: 0;
	border: 0;
}

/* center a footer to bottom*/
footer {
	position: fixed;
	bottom: 0;
	width: calc(100% - 20px);
	margin: 10px;
	padding: 0;
	border: 0;
	background-color: rgba(255, 255, 255, 0.15);
	text-align: center;
}
