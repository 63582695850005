.background {
	background-image: url(../../../public/images/bus.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -2;
}

.background::after {
	content: "";
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.header {
	margin: 10px;
	position: relative;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1;
	color: white;
	font-size: 1em;
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	text-align: center;
	padding: 10px 20px;
	line-height: 1.5;
}

.buttons {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 0;
	/* bottom */
}

.linkButton {
	background-color: #f5f5f5;
	color: #000;
	border: 1px solid #000;
	border-radius: 5px;
	padding: 10px;
	text-decoration: none;
	font-size: 1em;
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	margin: 10px;
}
/*on hover*/
.linkButton:hover {
	background-color: #606060;
	color: #000000;
}
#dokumentaceLink {
	margin-top: 1em;
}
/* center horizontaly and verticaly*/
.center {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.red {
	color: red;
}
@media screen and (max-width: 1100px) {
	footer {
		/*botom of the page*/
		position: unset;
		bottom: 0;
		width: calc(100% - 20px);
		margin: 10px;
		padding: 0;
		border: 0;
		background-color: rgba(255, 255, 255, 0.15);
		text-align: center;
	}
}
